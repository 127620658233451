<template>
  <div class="mt-2">
    <div class="header d-flex">
      <div class="mr-auto">
        <!-- <div v-if="userIsGlobal" class="mb-1 pointer" @click="back"> -->
        <div class="mb-1 pointer" @click="back">
          <b-icon
            class="form-control-feedback"
            icon="arrow-left-circle"
          /> {{ $store.state.common.countries[countryCode] }}
        </div>
        <p class="mb-1">
          Create and manage passwords for the NikonDifference application.
        </p>
      </div>
    
      <div class="form-inline">
        <button
          type="button"
          class="btn btn-primary btn-cus"
          @click="add"
        >
          <b-icon
            class="form-control-feedback"
            icon="plus"
          /> Add more packages
        </button>
        <div class="input-group ml-2">
          <input
            id="example-search-input"
            v-model="searchText"
            class="form-control py-2 border-right-0 border"
            type="search"
            @keyup="search"
          >
          <span class="input-group-append">
            <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
            >
              <b-icon
                class="form-control-feedback"
                icon="search"
                @click="search"
              />
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        ref="searchTable" class="table table-sm table-center"
      >
        <thead>
          <tr>
            <th class="normal-th-content">
              SN
            </th>
            <th class="normal-th-content">
              Date
            </th>
            <th class="normal-th-content">
              Package Description
            </th>
            <th class="normal-th-content">
              Password
            </th>
            <th class="normal-th-content">
              Surveys
            </th>
            <th class="normal-th-content">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(svpackage, index) in svpackages"
            :key="svpackage.id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ svpackage.updatedAt | formatDate }}</td>
            <td>{{ svpackage.description }}</td>
            <td>{{ svpackage.password }}</td>
            <td>{{ svpackage.totalSurveys }}</td>
            <td>
              <b-icon
                class="pointer"
                icon="pencil-fill"
                @click="edit(svpackage)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import PackageSurveyService from '../../../service/PackageSurveyService'
  export default {
    name: 'SurveyPackageDetail',
    props: {
      countryCode: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        searchText: '',
        svpackages: [],
        userIsGlobal:false
      }
    }, 
    mounted(){
      if(this.$store.state.auth.user?.isGlobal) {
         this.userIsGlobal = true
      }
      PackageSurveyService.getAll(this.countryCode)
        .then((response) => {
          this.svpackages = response.data
        })
        .catch((reason) => {
          const data = reason.response.data
          if (data) {
            this.openToast(data.message);
          } else {
            this.openToast('Server error');
          }
        })
    },
    methods: {
      back() {
        this.$emit('back', this.countryCode)
      },
      search() {
        if (!this.searchText) {
          this.searchText = ""
        }
        const searchTable = this.$refs.searchTable;
        const trs = searchTable.getElementsByTagName("tr");
        for (let i = 0; i < trs.length; i++) {
          let tr = trs[i]
          let styleDisplay = this.searchInTd(tr, 1)
          if (styleDisplay) {
            styleDisplay = this.searchInTd(tr, 2)
          }
          if (styleDisplay) {
            styleDisplay = this.searchInTd(tr, 3)
          }
        }
      },
      searchInTd(tr, index) {
        let td = tr.getElementsByTagName("td")[index];
        let styleDisplay
        if (td) {
          let txtValue = td.textContent || td.innerText || "";
          styleDisplay = txtValue.toUpperCase().indexOf(this.searchText.toUpperCase()) > -1 ? "" : "none";
          tr.style.display = styleDisplay;
        }
        return styleDisplay
      },
      add() {
        this.$emit('add')
      },
      edit(svpackage) {
        this.$emit('edit', svpackage)
      },
      openToast(message) {
        console.log(message)
      }
    }
  }
</script>

<style scoped>
  .edit-col {
    width: 75px;
  }
  .edit-col .btn {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
</style>